/* eslint:disable */
/**
 * Business Local UGC Service
 * Business Local UGC Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ErrorResponse } from '../../models';
import { GetLocalProfileReview200Response } from '../../models';
/**
 * LocalProfileReviewApi - axios parameter creator
 * @export
 */

/**
 * getLocalProfileReview parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetLocalProfileReviewParams = {
    reviewId: number;
}
/**
 * 후기 단건 조회. authToken은 viewer로 취급돼요.
 * @summary 후기 단건 조회
 */
const getLocalProfileReviewAxiosParamCreator = async (
  // @ts-ignore
  params: GetLocalProfileReviewParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/local_profile_reviews/{review_id}`
    .replace(`{${"review_id"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 후기 단건 조회. authToken은 viewer로 취급돼요.
 * @summary 후기 단건 조회
 */
const getLocalProfileReviewFp = async ({
  params,
  options,
}: {
  params: GetLocalProfileReviewParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetLocalProfileReview200Response>> => {
  const localVarAxiosArgs = await getLocalProfileReviewAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const getLocalProfileReview = ({
  params,
  options,
}: {
  params: GetLocalProfileReviewParams;
  options?: AxiosRequestConfig,
}) => {
  return getLocalProfileReviewFp({ params, options, });
};

