import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";

type PathParams = {};
type QueryParams = {
  contribution_type: "review_create" | "local_profile_update";
};

export type ActivityLocalProfileContributeRecommendationsParams = PathParams &
  QueryParams;

const ActivityLocalProfileContributeRecommendationsLayout: ActivityLayoutComponentType<
  ActivityLocalProfileContributeRecommendationsParams
> = ({ children }) => {
  return <Screen>{children}</Screen>;
};

export default ActivityLocalProfileContributeRecommendationsLayout;
