import { useReferrerAndEntry } from "../../../../entities/referrer/lib";
import { LOCAL_BUSINESS_UGC_SERVICE_NAME } from "../../../../shared/constants/environment";
import { BottomFixedButton } from "../../../../shared/ui/layout/BottomFixedButton";
import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";
import { useNavigator } from "../../../../stackflow/useNavigator";

const LOCAL_PROFILE_CONTRIBUTE_RECOMMENDATIONS_PAGE = `${LOCAL_BUSINESS_UGC_SERVICE_NAME}.local_profile.contribute.recommendations`;

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {
  /**
   * 수정 제안 항목 (comma separated string)
   * - 순서대로 렌더링
   * - 미지정 시 모든 항목 렌더링
   */
  fields?: string;
};

export type ActivityLocalProfileUpdateParams = PathParams & QueryParams;

const ActivityLocalProfileUpdateLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateParams
> = ({ children }) => {
  const { pop } = useNavigator();
  const { incomingEntry } = useReferrerAndEntry();

  return (
    <Screen>
      <BottomFixedButton
        actions={[
          {
            elementType: "button",
            type: "button",
            size: "xlarge",
            "aria-label": "완료",
            children: "완료",
            onClick: () => {
              // 진입점이 기여모드 장소추천 화면인 경우 2페이지 이전으로 이동합니다.
              // flow: 기여모드 메인 -push-> 기여모드 장소추천 -push-> 로컬프로필 수정 -pop(2)-> 기여모드 메인)
              if (
                incomingEntry === LOCAL_PROFILE_CONTRIBUTE_RECOMMENDATIONS_PAGE
              ) {
                pop(2);

                return;
              }
              pop();
            },
          },
        ]}
        children={children}
      />
    </Screen>
  );
};

export default ActivityLocalProfileUpdateLayout;
