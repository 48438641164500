import { Spinner } from "@daangn/sprout-components-spinner";

import * as css from "./Loading.css";

export const Loading = () => {
  return (
    <div className={css.fullPage}>
      <Spinner />
    </div>
  );
};
