import { IS_PROD } from "./environment";

export const BUSINESS_REVIEW_SERVER_URL = IS_PROD
  ? "https://business-review.kr.karrotmarket.com"
  : "https://business-review.alpha.kr.karrotmarket.com";

/**
 * 로컬 비즈니스 UGC 서버 URL
 */
export const LOCAL_BUSINESS_UGC_SERVER_URL = IS_PROD
  ? "https://business-local-ugc.kr.karrotmarket.com"
  : "https://business-local-ugc.alpha.kr.karrotmarket.com";

/**
 * 비즈 계정 서버 URL
 * https://ba.alpha.kr.krmt.io/swagger-ui/index.html
 */
export const BUSINESS_ACCOUNT_SERVER_URL = IS_PROD
  ? "https://ba.kr.karrotmarket.com"
  : "https://ba.alpha.kr.karrotmarket.com";

/**
 * POI 서버 URL
 * https://poi.alpha.kr.karrotmarket.com/swagger/v2/
 */
export const POI_SERVER_URL = IS_PROD
  ? `https://poi.kr.karrotmarket.com`
  : `https://poi.alpha.kr.karrotmarket.com`;

/**
 * 커머스 서버 GraphQL Endpoint
 */
export const COMMERCE_GRAPHQL_SERVER_URL = IS_PROD
  ? "https://commerce-graphql.kr.karrotmarket.com"
  : "https://commerce-graphql.alpha.kr.karrotmarket.com";

/**
 * 비즈 소식 서버 URL
 */
export const BUSINESS_POST_SERVER_URL = IS_PROD
  ? "https://business-post.kr.karrotmarket.com"
  : "https://business-post.alpha.kr.karrotmarket.com";

export const BIG_PICTURE_BASE_URL = IS_PROD
  ? "https://image.kr.karrotmarket.com"
  : "https://image.alpha.kr.karrotmarket.com";

/**
 * 동네지도 서버 URL
 */
export const LOCAL_MAP_SERVER_URL = IS_PROD
  ? "https://local-map.kr.karrotmarket.com"
  : "https://local-map.alpha.kr.karrotmarket.com";

/**
 * 비즈니스 게이트웨이 서버 URL
 */
export const BUSINESS_GATEWAY_SERVER_URL = IS_PROD
  ? "https://business-gateway.daangn.com/"
  : "https://business-gateway.alpha.daangn.com/";
