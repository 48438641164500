/* eslint:disable */
/**
 * Business Local UGC Service
 * Business Local UGC Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ErrorResponse } from '../../models';
import { GetUserContributedLocalProfiles200Response } from '../../models';
import { GetUserContributionInfo200Response } from '../../models';
import { GetUserReviews200Response } from '../../models';
import { GetUserSuggestions200Response } from '../../models';
/**
 * UserContributionApi - axios parameter creator
 * @export
 */

/**
 * getUserContributedLocalProfiles parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetUserContributedLocalProfilesParams = {
    userId: number;
    page?: number;
    size?: number;
}
/**
 * 사용자가 기여한 로컬프로필 목록 조회
 * @summary 사용자가 기여한 로컬프로필 목록 조회
 */
const getUserContributedLocalProfilesAxiosParamCreator = async (
  // @ts-ignore
  params: GetUserContributedLocalProfilesParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/users/{user_id}/contributed_local_profiles`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getUserContributionInfo parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetUserContributionInfoParams = {
    userId: number;
}
/**
 * 사용자 기여 정보 조회
 * @summary 사용자 기여 정보 조회
 */
const getUserContributionInfoAxiosParamCreator = async (
  // @ts-ignore
  params: GetUserContributionInfoParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/users/{user_id}/profile`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getUserReviews parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetUserReviewsParams = {
    userId: number;
    page?: number;
    size?: number;
}
/**
 * 사용자 리뷰 목록 조회
 * @summary 사용자 리뷰 목록 조회
 */
const getUserReviewsAxiosParamCreator = async (
  // @ts-ignore
  params: GetUserReviewsParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/users/{user_id}/local_profile_reviews`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getUserSuggestions parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetUserSuggestionsParams = {
    userId: number;
    statuses?: Array<'PROCESSING' | 'RESOLVED' | 'SKIPPED'>;
    page?: number;
    size?: number;
}
/**
 * 사용자 제안 목록 조회
 * @summary 사용자 제안 목록 조회
 */
const getUserSuggestionsAxiosParamCreator = async (
  // @ts-ignore
  params: GetUserSuggestionsParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/users/{user_id}/local_profile_suggestions`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params["statuses"]) {
          localVarQueryParameter['statuses'] = params['statuses'];
      }

      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 사용자가 기여한 로컬프로필 목록 조회
 * @summary 사용자가 기여한 로컬프로필 목록 조회
 */
const getUserContributedLocalProfilesFp = async ({
  params,
  options,
}: {
  params: GetUserContributedLocalProfilesParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetUserContributedLocalProfiles200Response>> => {
  const localVarAxiosArgs = await getUserContributedLocalProfilesAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 사용자 기여 정보 조회
 * @summary 사용자 기여 정보 조회
 */
const getUserContributionInfoFp = async ({
  params,
  options,
}: {
  params: GetUserContributionInfoParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetUserContributionInfo200Response>> => {
  const localVarAxiosArgs = await getUserContributionInfoAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 사용자 리뷰 목록 조회
 * @summary 사용자 리뷰 목록 조회
 */
const getUserReviewsFp = async ({
  params,
  options,
}: {
  params: GetUserReviewsParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetUserReviews200Response>> => {
  const localVarAxiosArgs = await getUserReviewsAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 사용자 제안 목록 조회
 * @summary 사용자 제안 목록 조회
 */
const getUserSuggestionsFp = async ({
  params,
  options,
}: {
  params: GetUserSuggestionsParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetUserSuggestions200Response>> => {
  const localVarAxiosArgs = await getUserSuggestionsAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const getUserContributedLocalProfiles = ({
  params,
  options,
}: {
  params: GetUserContributedLocalProfilesParams;
  options?: AxiosRequestConfig,
}) => {
  return getUserContributedLocalProfilesFp({ params, options, });
};
export const getUserContributionInfo = ({
  params,
  options,
}: {
  params: GetUserContributionInfoParams;
  options?: AxiosRequestConfig,
}) => {
  return getUserContributionInfoFp({ params, options, });
};
export const getUserReviews = ({
  params,
  options,
}: {
  params: GetUserReviewsParams;
  options?: AxiosRequestConfig,
}) => {
  return getUserReviewsFp({ params, options, });
};
export const getUserSuggestions = ({
  params,
  options,
}: {
  params: GetUserSuggestionsParams;
  options?: AxiosRequestConfig,
}) => {
  return getUserSuggestionsFp({ params, options, });
};

