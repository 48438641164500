export const STAGE = import.meta.env.VITE_STAGE;
export const IS_PROD =
  STAGE === "production" || STAGE === "development:production";
export const IS_DEV =
  STAGE === "development" || STAGE === "development:production";

export const IS_IOS = /iphone|ipad|ipod/i.test(
  window.navigator.userAgent.toLowerCase(),
);
export const IS_ANDROID = /Android/i.test(navigator.userAgent);

export const THEME = IS_IOS ? "cupertino" : "android";

export const RELEASE_VERSION = import.meta.env.RELEASE_VERSION;

/**
 * Mocking 여부 설정
 * - production 또는 alpha 환경에서는 무조건 false
 * - 개발 환경에서는 import.meta.env.USE_MOCK 값에 따라 설정
 */
export const USE_MOCK: boolean =
  STAGE === "production" || STAGE === "alpha"
    ? false
    : import.meta.env.USE_MOCK === "true";

/**
 * Sentry
 */
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

// Datadog
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
export const DATADOG_APPLICATION_ID = import.meta.env
  .VITE_DATADOG_APPLICATION_ID;

export const LOCAL_BUSINESS_UGC_SERVICE_NAME = "local_business_ugc";
