import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { EmptyOrError } from "../../../shared/ui/empty-or-error/EmptyOrError";
import { useNavigator } from "../../../stackflow/useNavigator";

export const CREATE_REVIEW_ERROR_MESSAGES = {
  LOCAL_PROFILE_NOT_FOUND:
    "후기를 작성할 업체를 찾을 수 없어요. 잠시 후 다시 시도해주세요.",
  LOCAL_PROFILE_INACTIVE:
    "후기를 작성할 업체가 삭제되었어요. 삭제된 업체에는 후기를 작성할 수 없어요.",
  USER_IS_REVIEWABLE_FALSE: "후기를 작성할 수 없어요.",
  RESOURCE_NOT_FOUND: "후기를 작성할 수 없어요.",
  UNKNOWN_ERROR: "에러가 발생했어요. 잠시 후 다시 시도해주세요.",
};

const CREATE_REVIEW_ERROR_NAME = "CUSTOM_ERROR:CREATE_REVIEW";
type ErrorCode = keyof typeof CREATE_REVIEW_ERROR_MESSAGES;
export class CreateReviewError extends Error {
  code: ErrorCode;

  name: typeof CREATE_REVIEW_ERROR_NAME;

  constructor(code: ErrorCode) {
    super(code);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, CreateReviewError.prototype);
    // Optionally, you can capture the stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CreateReviewError);
    }
    this.name = "CUSTOM_ERROR:CREATE_REVIEW";
    // Set the custom code
    this.code = code;
  }
}

interface CreateReviewErrorBoundaryProps {
  children: ReactNode;
}
export const CreateReviewErrorBoundary = (
  props: CreateReviewErrorBoundaryProps,
) => {
  const { pop } = useNavigator();

  const handleClick = () => {
    pop();
  };

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => {
        return (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ error, resetErrorBoundary }) => {
              let message = CREATE_REVIEW_ERROR_MESSAGES.UNKNOWN_ERROR;
              if (error instanceof CreateReviewError) {
                message = CREATE_REVIEW_ERROR_MESSAGES[error.code];
              }

              return (
                <EmptyOrError
                  body={message}
                  CTAButton={{
                    label: "뒤로가기",
                    onClick: () => {
                      resetErrorBoundary();
                      handleClick();
                    },
                  }}
                />
              );
            }}
          >
            {props.children}
          </ErrorBoundary>
        );
      }}
    </QueryErrorResetBoundary>
  );
};
