import { localBusinessAnalyticsPlugin } from "@daangn/local-business-analytics";

import { type ClickEventTypes } from "./clickEventTypes";
import { type RecommendedUgcPerformanceAnalyticsEventTypes } from "./recommendedUgcPerformanceEventTypes";
import { type ShowEventTypes } from "./showEventTypes";
export const {
  useLogEvent: useLogAnalyticsEvent,
  useUnregisterScreenCommonPayload,
  useRegisterScreenCommonPayloadEffect,
  useRegisterScreenCommonPayload,
  plugin: localBusinessAnalyticsStackflowPlugin, // stackflow plugin
} = localBusinessAnalyticsPlugin<
  | ClickEventTypes
  | ShowEventTypes
  | RecommendedUgcPerformanceAnalyticsEventTypes
>();
