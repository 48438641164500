import { useRedirectUserProfileEffect } from "../../../../features/user-profile/model/useRedirectUserProfileEffect";
import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";
type PathParams = {
  /**
   * 프로필 ID
   */
  profile_id: string;
};
type QueryParams = {};
export type ActivityUserProfileSuggestionsParams = PathParams & QueryParams;

const ActivityLayoutUserProfileSuggestions: ActivityLayoutComponentType<
  ActivityUserProfileSuggestionsParams
> = ({ children, params }) => {
  useRedirectUserProfileEffect({
    activityName: "profile.local_profile_suggestions",
    profileUserId: params.profile_id,
  });

  return <Screen appBar={{ title: "장소 제안" }}>{children}</Screen>;
};

export default ActivityLayoutUserProfileSuggestions;
