type CacheState<T> =
  | {
      _t: "hasValue";
      state: T;
    }
  | {
      _t: "hasError";
      error: Error;
    }
  | {
      _t: "initial";
    };

const promiseMap = new Map<string, Promise<any>>();
const stateMap = new Map<string, CacheState<any>>();

export const getPromise = (key: string) => {
  return promiseMap.get(key);
};

export const getCacheState = <T>(key: string): CacheState<T> => {
  if (!stateMap.has(key)) {
    stateMap.set(key, { _t: "initial" });
  }

  return stateMap.get(key)!;
};

const setCacheState = <T>(key: string, value: CacheState<T>) => {
  stateMap.set(key, value);
};

type Loadable<R> = {
  key: string;
  promise: Promise<R>;
};

export const createExternalPromise = <R>({
  api,
  key,
}: {
  key: string;
  api: () => Promise<R>;
}) => {
  promiseMap.set(
    key,
    api()
      .then((res) => {
        setCacheState(key, {
          _t: "hasValue",
          state: res,
        });

        return res;
      })
      .catch((error) => {
        setCacheState(key, {
          _t: "hasError",
          error,
        });
      }),
  );

  return promiseMap.get(key)!;
};
