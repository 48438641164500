import type { StackflowReactPlugin } from "@stackflow/react";

import { DisableSocialCapitalActivity } from "../../widgets/social-capital/DisableSocialCapitalActivity";

const SOCIAL_CAPITAL_ACTIVITY_PREFIX = "social_capital";
const SOCIAL_CAPITAL_ACTIVITIES = [
  "social_capital.redirect.dashboard",
  "social_capital.onboarding",
  "social_capital.onboarding.region_selection_bottom_sheet",
  "social_capital.dashboard",
  "social_capital.ranking",
  "social_capital.point_system",
  "social_capital.review.landing",
  "social_capital.review.contribute_landing",
  "social_capital.suggestion.contribute_landing",
];

function isSocialCapitalActivity(activityName: string) {
  return (
    activityName.startsWith(SOCIAL_CAPITAL_ACTIVITY_PREFIX) ||
    SOCIAL_CAPITAL_ACTIVITIES.includes(activityName)
  );
}

export const disableSocialCapitalPlugin = (): StackflowReactPlugin => {
  return () => ({
    key: "enable-social-capital-plugin",
    wrapActivity({ activity }) {
      if (isSocialCapitalActivity(activity.name)) {
        return (
          <DisableSocialCapitalActivity
            redirectActivity="error.not_found"
            redirectParams={{}}
          >
            {activity.render()}
          </DisableSocialCapitalActivity>
        );
      }

      return <>{activity.render()}</>;
    },
  });
};
