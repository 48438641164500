export const DAANGN_ICON_URL =
  "https://assetstorage.krrt.io/1087914561898631544/dcd5d767-f937-48d2-b7da-dcd9680eba27/width=76,height=76.png";

export const SOCIAL_CAPITAL_DAANGNI_BACKGROUND_URL =
  "https://assetstorage.krrt.io/1087914561898631544/75528c30-d716-4972-aa1e-9ff84214efaa/width=500,height=269.png";
export const SOCIAL_CAPITAL_DAANGNI_WITHOUT_GRASS_BACKGROUND_URL =
  "https://assetstorage.krrt.io/1087914561898631544/c5127616-32b8-4b62-a626-f36b67f331a7/width=500,height=269.png";

export const PROD_SHARE_ONE_LINK_URL =
  "https://daangn.onelink.me/oWdR/z1xejmfi";

export const ALPHA_SHARE_ONE_LINK_URL =
  "https://daangna.onelink.me/vh7m/9q7712b2";

export const SOCIAL_CAPITAL_FAQ_URL = "https://www.daangn.com/wv/faqs/15054";
