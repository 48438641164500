import React from "react";

import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";
import { type ActivityParams } from "./ActivitySocialCapitalSelectLegalRegions";

const ActivitySocialCapitalSelectLegalRegionsLayout: ActivityLayoutComponentType<
  ActivityParams
> = ({ children }) => {
  return <Screen>{children}</Screen>;
};

export default ActivitySocialCapitalSelectLegalRegionsLayout;
