import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";

type PathParams = {
  /**
   * 후기 ID
   */
  review_id: string;
};
type QueryParams = {};
export type ActivityReviewLandingParams = PathParams & QueryParams;

const ActivityReviewLandingLayout: ActivityLayoutComponentType<
  ActivityReviewLandingParams
> = ({ children }) => {
  return <Screen>{children}</Screen>;
};

export default ActivityReviewLandingLayout;
