// import { useMemo } from "react";

// import { useVerifyKarrotEmployeeQuery } from "../../../entities/karrot/model";
// import { IS_PROD } from "../../../shared/constants/environment";

/**
 * 소셜캐피탈(당근모으기) 서비스 사용 가능 여부를 확인합니다.
 * 전국배포가 안정화되면 `useSocialCapitalAvailability`의 사용을 제거해요.
 * - isSocialCapitalAvailable
 *   - "available": 소셜캐피탈 서비스 사용 가능
 *   - "unavailable": 소셜캐피탈 서비스 사용 불가
 */
export const useSocialCapitalAvailability = (): {
  isLoading: boolean;
  isSocialCapitalAvailable: "available" | "unavailable";
} => {
  return {
    isLoading: false,
    isSocialCapitalAvailable: "available",
  };

  // const { data, isLoading } = useVerifyKarrotEmployeeQuery();
  // const isEmployee = data?.isEmployee ?? false;

  // const isSocialCapitalAvailable: "available" | "unavailable" = useMemo(
  //   () => (isEmployee ? "available" : "unavailable"),
  //   [isEmployee],
  // );

  // // 편의를 위해 알파 환경에서는 항상 사용 가능하게 설정합니다.
  // if (!IS_PROD) {
  //   return {
  //     isLoading,
  //     isSocialCapitalAvailable: "available",
  //   };
  // }

  // return {
  //   isLoading,
  //   isSocialCapitalAvailable,
  // };
};
