import clsx from "clsx";
import { type PropsWithChildren } from "react";

import * as css from "./Flex.css";

export type FlexProps = css.BaseVariants & { className?: string };
export const Flex = ({
  children,
  className,
  ...styleProps
}: PropsWithChildren<FlexProps>) => {
  return (
    <div className={clsx(css.base(styleProps), className)}>{children}</div>
  );
};
