import { QueryErrorResetBoundary } from "@tanstack/react-query";
import React, { type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { EmptyOrError } from "../../../../shared/ui/empty-or-error/EmptyOrError";
import { useNavigator } from "../../../../stackflow/useNavigator";

interface Props {
  children: ReactNode;
}
const ErrorBoundaryReviewPerformanceLanding = ({ children }: Props) => {
  const { pop } = useNavigator();

  const handleClick = () => {
    pop();
  };

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => {
        return (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={() => {
              const message = (
                <p style={{ textAlign: "center" }}>
                  작성한 후기를 찾을 수 없어요. <br />
                  잠시 후 다시 시도해주세요.
                </p>
              );

              return (
                <EmptyOrError
                  body={message}
                  CTAButton={{
                    label: "뒤로가기",
                    onClick: () => {
                      handleClick();
                    },
                  }}
                />
              );
            }}
          >
            {children}
          </ErrorBoundary>
        );
      }}
    </QueryErrorResetBoundary>
  );
};

export default ErrorBoundaryReviewPerformanceLanding;
