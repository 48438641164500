import { Screen } from "../../../shared/ui/screen/Screen";
import { ActivityLayoutComponentType } from "../../../stackflow/hocs/withActivityRenderer";

export type ActivitySocialCapitalPointSystemParams = {};

const ActivitySocialCapitalPointSystemLayout: ActivityLayoutComponentType<
  ActivitySocialCapitalPointSystemParams
> = ({ children }) => {
  return <Screen appBar={{ border: false }}>{children}</Screen>;
};

export default ActivitySocialCapitalPointSystemLayout;
