/* eslint:disable */
/**
 * Business Local UGC Service
 * Business Local UGC Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ErrorResponse } from '../../models';
import { GetRecommendedContributionByUser200Response } from '../../models';
import { GetRecommendedLocalProfilesByUser200Response } from '../../models';
import { RecommendedContributionType } from '../../models';
/**
 * RecommendationApi - axios parameter creator
 * @export
 */

/**
 * getRecommendedContributionByUser parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetRecommendedContributionByUserParams = {
    userId: number;
    limit?: number;
    contributionTypes?: Array<RecommendedContributionType>;
}
/**
 * 추천할 기여 목록 조회
 * @summary 추천할 기여 목록 조회
 */
const getRecommendedContributionByUserAxiosParamCreator = async (
  // @ts-ignore
  params: GetRecommendedContributionByUserParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/users/{user_id}/recommended_contribution`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['limit'] !== undefined) {
          localVarQueryParameter['limit'] = params['limit'];
      }

      if (params["contributionTypes"]) {
          localVarQueryParameter['contribution_types'] = params['contributionTypes'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getRecommendedLocalProfilesByUser parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetRecommendedLocalProfilesByUserParams = {
    userId: number;
    limit?: number;
    userCurrentLatitude?: number;
    userCurrentLongitude?: number;
    availableContributions?: Array<'REVIEW'>;
}
/**
 * 유저가 알만한 로컬프로필들을 추천합니다.
 * @summary 추천 로컬프로필 목록 조회
 */
const getRecommendedLocalProfilesByUserAxiosParamCreator = async (
  // @ts-ignore
  params: GetRecommendedLocalProfilesByUserParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/users/{user_id}/recommended_local_profiles`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['limit'] !== undefined) {
          localVarQueryParameter['limit'] = params['limit'];
      }

      if (params['userCurrentLatitude'] !== undefined) {
          localVarQueryParameter['user_current_latitude'] = params['userCurrentLatitude'];
      }

      if (params['userCurrentLongitude'] !== undefined) {
          localVarQueryParameter['user_current_longitude'] = params['userCurrentLongitude'];
      }

      if (params["availableContributions"]) {
          localVarQueryParameter['available_contributions'] = params['availableContributions'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 추천할 기여 목록 조회
 * @summary 추천할 기여 목록 조회
 */
const getRecommendedContributionByUserFp = async ({
  params,
  options,
}: {
  params: GetRecommendedContributionByUserParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetRecommendedContributionByUser200Response>> => {
  const localVarAxiosArgs = await getRecommendedContributionByUserAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 유저가 알만한 로컬프로필들을 추천합니다.
 * @summary 추천 로컬프로필 목록 조회
 */
const getRecommendedLocalProfilesByUserFp = async ({
  params,
  options,
}: {
  params: GetRecommendedLocalProfilesByUserParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetRecommendedLocalProfilesByUser200Response>> => {
  const localVarAxiosArgs = await getRecommendedLocalProfilesByUserAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const getRecommendedContributionByUser = ({
  params,
  options,
}: {
  params: GetRecommendedContributionByUserParams;
  options?: AxiosRequestConfig,
}) => {
  return getRecommendedContributionByUserFp({ params, options, });
};
export const getRecommendedLocalProfilesByUser = ({
  params,
  options,
}: {
  params: GetRecommendedLocalProfilesByUserParams;
  options?: AxiosRequestConfig,
}) => {
  return getRecommendedLocalProfilesByUserFp({ params, options, });
};

