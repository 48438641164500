import { useUserProfileInfoByUserId } from "../../../../features/user-profile/lib/useUserProfileInfoByUserId";
import { useRedirectUserProfileEffect } from "../../../../features/user-profile/model/useRedirectUserProfileEffect";
import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";

type PathParams = {
  /**
   * 프로필 ID
   */
  profile_id: string;
};
type QueryParams = {};
export type ActivityUserProfileContributedLocalProfilesParams = PathParams &
  QueryParams;

const ActivityLayoutUserProfileContributedLocalProfiles: ActivityLayoutComponentType<
  ActivityUserProfileContributedLocalProfilesParams
> = ({ params, children }) => {
  const { isMyProfile } = useUserProfileInfoByUserId({
    profileUserId: params.profile_id,
  });

  useRedirectUserProfileEffect({
    activityName: "profile.contributed_local_profiles",
    profileUserId: params.profile_id,
  });

  return (
    <Screen
      appBar={{
        title: isMyProfile ? "내가 도움을 준 곳" : "도움을 준 곳",
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivityLayoutUserProfileContributedLocalProfiles;
