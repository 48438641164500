import { captureException, setUser as setSentryUser } from "@sentry/react";
import { type PropsWithChildren, Suspense } from "react";

import { KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY } from "../features/karrot-bridge/model/constants";
import type {
  GetRegionInfo,
  GetUserInfo,
} from "../features/karrot-bridge/model/createExternalPromiseBridgeInfo";
import { useExternalPromiseLoader } from "../shared/lib/karrot-bridge/external-promise-loader/useExternalPromiseLoader";

export const Bridge = ({ children }: PropsWithChildren) => {
  useExternalPromiseLoader<GetUserInfo>({
    key: KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY.USER_INFO,
    onSuccess: (user) => {
      setSentryUser({ id: user.id });
    },
    onFailure: (error) => {
      captureException(error);
    },
  });
  useExternalPromiseLoader<GetRegionInfo>({
    key: KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY.REGION,
    onFailure: (error) => {
      captureException(error);
    },
  });

  return children;
};
