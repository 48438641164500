import { SnackbarProvider } from "@daangn/sprout-components-snackbar";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { bridge } from "../shared/lib/karrot-bridge/bridge";
import { queryClient } from "../shared/lib/react-query/queryClient";
import { EmptyOrError } from "../shared/ui/empty-or-error/EmptyOrError";
import { Stack } from "../stackflow/Stack";
import { Bridge } from "./Bridge";

// App
const App = () => (
  <Suspense>
    <ErrorBoundary
      fallbackRender={() => {
        return (
          <div style={{ width: "100vw", height: "100vh" }}>
            <EmptyOrError
              body="에러가 발생했어요"
              CTAButton={{
                label: "뒤로가기",
                onClick: () => {
                  bridge.closeRouter({});
                },
              }}
            />
          </div>
        );
      }}
    >
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider offset="auto">
          <Bridge>
            <Stack />
          </Bridge>
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  </Suspense>
);

export default App;
