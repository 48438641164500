/* eslint:disable */
/**
 * Business Local UGC Service
 * Business Local UGC Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ErrorResponse } from '../../models';
import { GetLocalProfileContributions200Response } from '../../models';
import { GetLocalProfileContributors200Response } from '../../models';
/**
 * LocalProfileContributionApi - axios parameter creator
 * @export
 */

/**
 * getLocalProfileContributions parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetLocalProfileContributionsParams = {
    localProfileId: number;
    page?: number;
    size?: number;
}
/**
 * 로컬프로필 기여 목록 조회
 * @summary 로컬프로필 기여 목록 조회
 */
const getLocalProfileContributionsAxiosParamCreator = async (
  // @ts-ignore
  params: GetLocalProfileContributionsParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/local_profiles/{local_profile_id}/contributions`
    .replace(`{${"local_profile_id"}}`, encodeURIComponent(String(params["localProfileId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getLocalProfileContributors parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetLocalProfileContributorsParams = {
    localProfileId: number;
    page?: number;
    size?: number;
}
/**
 * 로컬프로필 기여자 목록 조회
 * @summary 로컬프로필 기여자 목록 조회
 */
const getLocalProfileContributorsAxiosParamCreator = async (
  // @ts-ignore
  params: GetLocalProfileContributorsParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/local_profiles/{local_profile_id}/contributors`
    .replace(`{${"local_profile_id"}}`, encodeURIComponent(String(params["localProfileId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 로컬프로필 기여 목록 조회
 * @summary 로컬프로필 기여 목록 조회
 */
const getLocalProfileContributionsFp = async ({
  params,
  options,
}: {
  params: GetLocalProfileContributionsParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetLocalProfileContributions200Response>> => {
  const localVarAxiosArgs = await getLocalProfileContributionsAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 로컬프로필 기여자 목록 조회
 * @summary 로컬프로필 기여자 목록 조회
 */
const getLocalProfileContributorsFp = async ({
  params,
  options,
}: {
  params: GetLocalProfileContributorsParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetLocalProfileContributors200Response>> => {
  const localVarAxiosArgs = await getLocalProfileContributorsAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const getLocalProfileContributions = ({
  params,
  options,
}: {
  params: GetLocalProfileContributionsParams;
  options?: AxiosRequestConfig,
}) => {
  return getLocalProfileContributionsFp({ params, options, });
};
export const getLocalProfileContributors = ({
  params,
  options,
}: {
  params: GetLocalProfileContributorsParams;
  options?: AxiosRequestConfig,
}) => {
  return getLocalProfileContributorsFp({ params, options, });
};

