import { datadogRum } from "@datadog/browser-rum";

import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  RELEASE_VERSION,
  STAGE,
} from "../../constants/environment";

const createDatadogRUM = () => {
  const initialize = () => {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      // `*-webview` suffix is needed to be captured in the company-wide frontend RUM dashboard (https://app.datadoghq.com/dashboard/k6b-zsp-c2i?fromUser=false&refresh_mode=sliding&view=spans&from_ts=1714630548700&to_ts=1714634148700&live=true).
      service: "business-review-client-webview",
      env: STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: RELEASE_VERSION,
      // Adjust sample rate to to have less than 3k sessions per week
      sessionSampleRate: STAGE === "production" ? 20 : 100,
      sessionReplaySampleRate: 0.2,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      // All karrotmarket and daangn domains are allowed to be traced
      // allowedTracingUrls: [/.*\.(karrotmarket|daangn)\.com/g],
      allowedTracingUrls: [
        // business-review server
        /business-review\.(kr|alpha\.kr)\.karrotmarket\.com/,
        // UGC server
        /business-local-ugc\.(kr|alpha\.kr)\.karrotmarket\.com/,
      ],
    });
  };

  return {
    initialize,
  };
};

export const datadogRUM = createDatadogRUM();
