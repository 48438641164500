/* eslint:disable */

/**
 *
 * @export
 */
export const setSearchParams = function (url: URL, ...objects: any[]) {
  const searchParams = new URLSearchParams(url.search)
  for (const object of objects) {
    for (const key in object) {
      if (Array.isArray(object[key])) {
        searchParams.delete(key)
        for (const item of object[key]) {
          searchParams.append(key, item)
        }
      } else {
        searchParams.set(key, object[key])
      }
    }
  }
  url.search = searchParams.toString()
}

/**
 *
 * @export
 */
export const toPathString = function (url: URL) {
  return url.pathname + url.search + url.hash
}
