import { useUserProfileInfoByUserId } from "../../../features/user-profile/lib/useUserProfileInfoByUserId";
import { useRedirectUserProfileEffect } from "../../../features/user-profile/model/useRedirectUserProfileEffect";
import { Screen } from "../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../stackflow/hocs/withActivityRenderer";

type PathParams = {
  /**
   * 프로필 ID
   */
  profile_id: string;
};
type QueryParams = {};
export type ActivityUserProfileParams = PathParams & QueryParams;

const ActivityLayoutUserProfile: ActivityLayoutComponentType<
  ActivityUserProfileParams
> = ({ children, params }) => {
  const { isMyProfile } = useUserProfileInfoByUserId({
    profileUserId: params.profile_id,
  });

  useRedirectUserProfileEffect({
    activityName: "profile",
    profileUserId: params.profile_id,
  });

  return (
    <Screen
      appBar={{
        title: isMyProfile ? "내가 참여한 동네 장소" : "참여한 동네 장소",
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivityLayoutUserProfile;
