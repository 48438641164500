import React from "react";

import { Screen } from "../../../../shared/ui/screen/Screen";
import type { ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";

const ActivitySocialCapitalRedirectOnboardingOrDashboardLayout: ActivityLayoutComponentType<{}> =
  ({ children }) => {
    return <Screen appBar={{}}>{children}</Screen>;
  };

export default ActivitySocialCapitalRedirectOnboardingOrDashboardLayout;
