import { type ActivityComponentType } from "@stackflow/react";
import { useCallback, useMemo } from "react";

import { getCurrentQueryParams } from "../shared/utils/url/getCurrentQueryParams";
import { type Activities, type TypeUseStepFlow, useStepFlow } from "./Stack";

type StepNavigator = ReturnType<TypeUseStepFlow>;

export const useStepNavigator = <K extends Extract<keyof Activities, string>>(
  activityName: K,
) => {
  const { stepPush, stepReplace, stepPop } = useStepFlow(activityName);

  const modifiedPush = useCallback(
    (
      params: Activities[K] extends
        | ActivityComponentType<infer U>
        | {
            component: ActivityComponentType<infer U>;
          }
        ? U
        : Record<string, unknown>,
      options?: Parameters<StepNavigator["stepPush"]>["1"],
    ) => {
      const modifiedParams = {
        ...getCurrentQueryParams(),
        ...params,
      };

      return stepPush(modifiedParams, options);
    },
    [stepPush],
  );

  const modifiedReplace = useCallback(
    (
      params: Activities[K] extends
        | ActivityComponentType<infer U>
        | {
            component: ActivityComponentType<infer U>;
          }
        ? U
        : Record<string, unknown>,
      options?: Parameters<StepNavigator["stepReplace"]>["1"],
    ) => {
      const modifiedParams = {
        ...getCurrentQueryParams(),
        ...params,
      };

      return stepReplace(modifiedParams, options);
    },
    [stepReplace],
  );

  const modifiedPop = useCallback(
    (options: Parameters<StepNavigator["stepPop"]>["0"]) => {
      return stepPop(options);
    },
    [stepPop],
  );

  return useMemo(
    () => ({
      stepPush: modifiedPush,
      stepReplace: modifiedReplace,
      stepPop: modifiedPop,
    }),
    [modifiedPush, modifiedReplace, modifiedPop],
  );
};
