import { getQueryString } from "./getQueryString";

export const getCurrentQueryParams = () => {
  const queryString = getQueryString();
  const searchParams = new URLSearchParams(queryString);

  const params: { [key: string]: string } = {};
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};
