import { type ActivityComponentType } from "@stackflow/react";
import { type ReactNode, Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { useSocialCapitalAvailability } from "../../features/social-capital/model";
import { type Activities } from "../../stackflow/Stack";
import { useNavigator } from "../../stackflow/useNavigator";

export const DisableSocialCapitalActivity = <
  K extends Extract<keyof Activities, string>,
>({
  children,
  redirectActivity,
  redirectParams,
}: {
  children: ReactNode;
  redirectActivity: K;
  redirectParams: Activities[K] extends
    | ActivityComponentType<infer U>
    | { component: ActivityComponentType<infer U> }
    ? U
    : Record<string, unknown>;
}) => {
  const { isSocialCapitalAvailable, isLoading } =
    useSocialCapitalAvailability();
  const { replace } = useNavigator();
  const [replaced, setReplaced] = useState(false);

  useEffect(() => {
    if (replaced || isLoading || isSocialCapitalAvailable === "available") {
      return;
    }
    if (isSocialCapitalAvailable === "unavailable") {
      replace(redirectActivity, { ...redirectParams });
      setReplaced(true);
    }
  }, [
    isLoading,
    isSocialCapitalAvailable,
    redirectActivity,
    redirectParams,
    replace,
    replaced,
  ]);

  return isSocialCapitalAvailable === "available" ? (
    <ErrorBoundary fallbackRender={() => null}>
      <Suspense fallback={null}>{children}</Suspense>
    </ErrorBoundary>
  ) : null;
};
