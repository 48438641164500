import { getCacheState, getPromise } from "./core";

export const useExternalPromiseLoader = <R>({
  key,
  onSuccess,
  onFailure,
}: {
  key: string;
  onSuccess?: (value: R) => void;
  onFailure?: (error: Error) => void;
}) => {
  const cacheState = getCacheState<R>(key);

  switch (cacheState._t) {
    case "initial":
      throw getPromise(key)
        ?.then((r) => {
          onSuccess?.(r);

          return r;
        })
        .catch(onFailure);
    case "hasError":
      throw cacheState.error;
    default:
      return { value: cacheState.state };
  }
};
