import authPlugin from "@daangn/plantae-plugin-auth";
import commonHeadersPlugin from "@daangn/plantae-plugin-common-headers";
import requestIdPlugin from "@daangn/plantae-plugin-request-id";
import axios, { type AxiosInstance, type CreateAxiosDefaults } from "axios";
import { createAxiosInterceptors } from "plantae/axios";

import { IS_DEV } from "../../constants/environment";
import { bridge } from "../karrot-bridge/bridge";

export const applyAxiosInterceptors = (axiosInstance: AxiosInstance) => {
  /**
   * Plantae에 plugin들을 적용해 생성한 fetch 클라이언트에요.
   * @see https://github.com/daangn/plantae
   */
  const { request, response } = createAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      // https://github.com/daangn/webview-network/tree/main/packages/plantae-plugin-common-headers
      commonHeadersPlugin({ bridge }),
      requestIdPlugin(),
      authPlugin({
        bridge,
        options: {
          /**
           * true: 브릿지로부터 Auth Token 값을 가져와 헤더에 주입해요.
           * false: 어떤 경우에도 Auth Token을 사용하지 않아요. Access Token 테스트용 혹은 기존에 이미 Auth Token을 주입해주는 로직이 있는 프로젝트를 위한 옵션이에요.
           */
          fallbackAuthToken: IS_DEV
            ? { value: import.meta.env.VITE_USER_AUTH_TOKEN }
            : true,
        },
      }),
    ],
  });

  axiosInstance.interceptors.request.use(
    request.onFulfilled,
    request.onRejected,
  );
  axiosInstance.interceptors.response.use(
    response.onFulfilled,
    response.onRejected,
  );
};

export const createAxios = (config?: CreateAxiosDefaults) => {
  const axiosInstance = axios.create(config);

  applyAxiosInterceptors(axiosInstance);

  return axiosInstance;
};
