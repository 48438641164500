import type { AxiosInstance } from 'axios'
import { NetworkFetchConfig, NetworkHostEnvironment, getBaseURL, NetworkHostsMap } from '../configs/requestConfig'
import { __internalSetFetchConfig as setBaseFetchConfig } from './oas/common'

const HOSTS: Required<NetworkHostsMap> = {
  production: "https://business-local-ugc.kr.karrotmarket.com",
  alpha: "https://business-local-ugc.alpha.kr.karrotmarket.com",
}

export function __internalSetFetchConfig(
  environment: NetworkHostEnvironment,
  config: NetworkFetchConfig = {}
): AxiosInstance {
  const { host, configs } = config
  const baseURL = getBaseURL({ defaultHosts: HOSTS, host, environment })

  return setBaseFetchConfig({
    ...configs,
    baseURL,
  })
}
