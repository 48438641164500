import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";
import ErrorBoundaryReviewPerformanceLanding from "./ErrorBoundaryReviewPerformanceLanding";

type PathParams = {
  /**
   * 후기 ID
   */
  review_id: string;
};
type QueryParams = {
  referrer?: string;
};
export type ActivityReviewPerformanceLandingParams = PathParams & QueryParams;

const ActivityReviewPerformanceLandingLayout: ActivityLayoutComponentType<
  ActivityReviewPerformanceLandingParams
> = ({ children }) => {
  return (
    <Screen appBar={{ backgroundColor: "transparent" }}>
      <ErrorBoundaryReviewPerformanceLanding>
        {children}
      </ErrorBoundaryReviewPerformanceLanding>
    </Screen>
  );
};

export default ActivityReviewPerformanceLandingLayout;
