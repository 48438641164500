import { BottomSheet } from "@stackflow/plugin-basic-ui";
import React from "react";

import type { ActivityLayoutComponentType } from "../../../../../../stackflow/hocs/withActivityRenderer";
import { useNavigator } from "../../../../../../stackflow/useNavigator";
import { type ActivityParams } from "./ActivitySocialCapitalOnboardingRegionSelectionBottomSheet";

const ActivitySocialCapitalOnboardingRegionSelectionBottomSheetLayout: ActivityLayoutComponentType<
  ActivityParams
> = ({ children }) => {
  const { pop } = useNavigator();

  return <BottomSheet onOutsideClick={() => pop()}>{children}</BottomSheet>;
};

export default ActivitySocialCapitalOnboardingRegionSelectionBottomSheetLayout;
