import { type ReactNode } from "react";

import { EmptyOrError } from "../../shared/ui/empty-or-error/EmptyOrError";
import { Flex } from "../../shared/ui/layout/Flex";
import { Screen } from "../../shared/ui/screen/Screen";
import { useNavigator } from "../../stackflow/useNavigator";
import * as css from "./MaintenanceModeSocialCapitalActivity.css";
import { MaintenanceModeSocialCapitalBoundary } from "./MaintenanceModeSocialCapitalBoundary";

export const MaintenanceModeSocialCapitalActivity = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { pop } = useNavigator();

  return (
    <MaintenanceModeSocialCapitalBoundary
      fallback={
        <Screen appBar={{ border: false }}>
          <EmptyOrError
            body={
              <Flex align="center" justify="center">
                <p className={css.base}>
                  더 빠르고 안정적인 당근을 <br />
                  만들기 위해 서비스를 점검하고 있어요. <br />
                  잠시 후 다시 시도해 주세요.
                </p>
              </Flex>
            }
            CTAButton={{
              label: "돌아가기",
              onClick: pop,
            }}
          />
        </Screen>
      }
    >
      {children}
    </MaintenanceModeSocialCapitalBoundary>
  );
};
