import { type ActivityComponentType } from "@stackflow/react";
import { type ComponentType, type ReactNode, Suspense } from "react";

import { Loading } from "../../shared/ui/loading/Loading";

const withActivityRenderer = <
  T extends {
    [K in keyof T]: string | undefined;
  },
>({
  component: Component,
  layout: Layout,
}: {
  component: any;
  layout: ActivityComponentType<T>;
}) => {
  const WithActivityRenderer: ActivityComponentType<T> = (props) => {
    return (
      <Layout {...props}>
        <Suspense fallback={<Loading />}>
          <Component {...props} />
        </Suspense>
      </Layout>
    );
  };

  return WithActivityRenderer;
};

export { withActivityRenderer };

export type ActivityLayoutComponentType<
  T extends {
    [K in keyof T]: string | undefined;
  } = {},
> = ComponentType<{
  params: T & ScreenBaseParams;
  children?: ReactNode;
}>;

export type ScreenBaseParams = {
  referrer?: string;
};
