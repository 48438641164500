import { BrowserTracing, init } from "@sentry/react";

import {
  RELEASE_VERSION,
  SENTRY_DSN,
  STAGE,
} from "../../constants/environment";

const createSentry = () => {
  const initialize = () => {
    init({
      environment: STAGE,
      release: RELEASE_VERSION,
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0,
      // Change sample rate to 0.1
      sampleRate: STAGE === "production" ? 0.5 : 1,
    });
  };

  return {
    initialize,
  };
};

export const sentry = createSentry();
