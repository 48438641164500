/* eslint:disable */
/**
 * Business Local UGC Service
 * Business Local UGC Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ErrorResponse } from '../../models';
import { GetLocalProfileSuggestion200Response } from '../../models';
/**
 * LocalProfileSuggestionApi - axios parameter creator
 * @export
 */

/**
 * getLocalProfileSuggestion parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetLocalProfileSuggestionParams = {
    suggestionId: number;
}
/**
 * 로컬프로필 생성/수정/삭제 제안을 상세 조회합니다.
 * @summary 로컬프로필 제안 상세 조회
 */
const getLocalProfileSuggestionAxiosParamCreator = async (
  // @ts-ignore
  params: GetLocalProfileSuggestionParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/local_profile_suggestions/{suggestion_id}`
    .replace(`{${"suggestion_id"}}`, encodeURIComponent(String(params["suggestionId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 로컬프로필 생성/수정/삭제 제안을 상세 조회합니다.
 * @summary 로컬프로필 제안 상세 조회
 */
const getLocalProfileSuggestionFp = async ({
  params,
  options,
}: {
  params: GetLocalProfileSuggestionParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetLocalProfileSuggestion200Response>> => {
  const localVarAxiosArgs = await getLocalProfileSuggestionAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const getLocalProfileSuggestion = ({
  params,
  options,
}: {
  params: GetLocalProfileSuggestionParams;
  options?: AxiosRequestConfig,
}) => {
  return getLocalProfileSuggestionFp({ params, options, });
};

