import { useScrollToTopEffect } from "@daangn/stackflow-plugin-scroll-to-top";
import { captureException } from "@sentry/react";
import {
  AppScreen,
  type AppScreenProps,
  useAppScreen,
} from "@stackflow/plugin-basic-ui";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { type PropsWithChildren, type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { userInfoStore } from "../../../entities/user/model/userInfoStore";
import { EmptyOrError } from "../empty-or-error/EmptyOrError";

const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const { scroll } = useAppScreen();

  useScrollToTopEffect(() => {
    scroll({ top: 0 });
  }, [scroll]);

  return <>{children}</>;
};

export const Screen = ({
  children,
  ...props
}: PropsWithChildren<AppScreenProps>) => {
  return (
    <AppScreen
      {...props}
      appBar={{
        ...props.appBar,
        border: props.appBar?.border ?? !!props.appBar?.title,
      }}
    >
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ error, resetErrorBoundary }) => {
              const userId = userInfoStore.getState().user?.id;

              captureException(error, {
                user: { id: userId },
              });

              return (
                <EmptyOrError
                  body="에러가 발생했어요"
                  CTAButton={{
                    label: "다시 시도하기",
                    onClick: () => {
                      resetErrorBoundary();
                    },
                  }}
                />
              );
            }}
          >
            {children}
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </AppScreen>
  );
};
