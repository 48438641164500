import { Fragment } from "react";

export function renderMultilineText(multilineText: string) {
  return multilineText.split("\n").map((text, idx) => (
    <Fragment key={idx}>
      {text}
      <br />
    </Fragment>
  ));
}
