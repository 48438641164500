import { type ActivityComponentType } from "@stackflow/react";

import { EmptyOrError } from "../../shared/ui/empty-or-error/EmptyOrError";
import { Screen } from "../../shared/ui/screen/Screen";
import { useNavigator } from "../../stackflow/useNavigator";

const ActivityNotFound: ActivityComponentType = () => {
  const { pop } = useNavigator();

  return (
    <Screen>
      <EmptyOrError
        body={"페이지를 찾을 수 없습니다."}
        CTAButton={{
          label: "뒤로가기",
          onClick: pop,
        }}
      />
    </Screen>
  );
};

export default ActivityNotFound;
