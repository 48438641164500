import { IS_PROD } from "../../constants/environment";
import { LOCAL_BUSINESS_UGC_SERVER_URL } from "../../constants/serverURL";
import { applyAxiosInterceptors } from "../../lib/axios/axiosInstance";
import {
  __internalSetFetchConfig,
  getLocalProfileContributions as _getLocalProfileContributions,
  getLocalProfileInterestedUsers as _getLocalProfileInterestedUsers,
  getLocalProfileReview as _getLocalProfileReview,
  getLocalProfileSuggestion as _getLocalProfileSuggestion,
  getRecommendedContributionByUser as _getRecommendedContributionByUser,
  getRecommendedLocalProfilesByUser as _getRecommendedLocalProfilesByUser,
  getUserContributedLocalProfiles as _getUserContributedLocalProfiles,
  getUserReviews as _getUserReviews,
  getUserSuggestions as _getUserSuggestions,
} from "../__generated__/local-business-network/ugcV1";
export type {
  Address,
  FormStatus,
  GetUserContributedLocalProfiles200ResponseDataInner,
  GetUserSuggestions200ResponseDataInner,
  GetUserSuggestionsParams,
  LocalProfile,
  OpeningHour,
  OpeningHourDayOfWeek,
  Review,
  ReviewExtend,
} from "../__generated__/local-business-network/ugcV1";

const axiosInstance = __internalSetFetchConfig(
  IS_PROD ? "production" : "alpha",
  { host: LOCAL_BUSINESS_UGC_SERVER_URL, configs: { timeout: 5000 } },
);
applyAxiosInterceptors(axiosInstance);

export const getLocalProfileContributions = (
  params: Parameters<typeof _getLocalProfileContributions>["0"]["params"],
) => _getLocalProfileContributions({ params });

export const getUserReviews = (
  params: Parameters<typeof _getUserReviews>["0"]["params"],
) => _getUserReviews({ params });

export const getLocalProfileReview = (
  params: Parameters<typeof _getLocalProfileReview>["0"]["params"],
) => _getLocalProfileReview({ params });

export const getUserSuggestions = (
  params: Parameters<typeof _getUserSuggestions>["0"]["params"],
) => _getUserSuggestions({ params });

/**
 * 로컬프로필 제안 단건 조회
 */
export const getLocalProfileSuggestion = (
  params: Parameters<typeof _getLocalProfileSuggestion>["0"]["params"],
) => _getLocalProfileSuggestion({ params });

export const getUserContributedLocalProfiles = (
  params: Parameters<typeof _getUserSuggestions>["0"]["params"],
) => _getUserContributedLocalProfiles({ params });

/**
 * 특정 유저에게 UGC 생산을 권장할 로컬프로필과 기여 타입을 조회합니다.
 */
export const getRecommendedContributionByUser = (
  params: Parameters<typeof _getRecommendedContributionByUser>["0"]["params"],
) => _getRecommendedContributionByUser({ params });

/**
 * 로컬프로필에 관심을 표한 유저수와 해당 유저들의 프로필 이미지를 조회합니다.
 */
export const getLocalProfileInterestedUsers = (
  params: Parameters<typeof _getLocalProfileInterestedUsers>["0"]["params"],
) => _getLocalProfileInterestedUsers({ params });

/**
 * 특정 유저에게 UGC 생상 추천을 유도할 로컬프로필 목록을 조회합니다.
 */
export const getRecommendedLocalProfilesByUser = (
  params: Parameters<typeof _getRecommendedLocalProfilesByUser>["0"]["params"],
) => _getRecommendedLocalProfilesByUser({ params });
