import { useActivity } from "@stackflow/react";
import { useMemo } from "react";

import { LOCAL_BUSINESS_UGC_SERVICE_NAME } from "../../../shared/constants/environment";
import { getQueryParam } from "../../../shared/utils/url/getQueryParam";

let _incomingInitialEntry: string | null = null;

/**
 * - currentScreenName: 서비스명을 포함한 현재 화면의 이름 (e.g. "local_business_ugc.profile")
 * - incomingReferrer: 당근 앱 최초의 집입점 (e.g. "share_event")
 * - incomingEntry: 직전 화면의 이름 (e.g. "local_business_ugc.profile")
 * - incomingInitialEntry: 현재 웹뷰 최초 진입 시점의 집입점 (e.g. "local_profile.home")
 * - outgoingReferrer: 타 서비스를 열때 사용할 referrer. referrer가 없을 경우 현재 화면의 이름을 사용
 * - outgoingEntry: 타 서비스를 열때 사용할 entry. 현재 화면의 이름을 사용
 */
export const useReferrerAndEntry = (): {
  currentScreenName: string;
  incomingReferrer: string | null;
  incomingEntry: string | null;
  incomingInitialEntry: string | null;
  outgoingReferrer: string;
  outgoingEntry: string;
} => {
  const referrerQueryParam = getQueryParam("referrer");
  const entryQueryParam = getQueryParam("entry");

  const { name: activityName, isRoot, isTop } = useActivity();
  const currentScreenName = [
    LOCAL_BUSINESS_UGC_SERVICE_NAME,
    activityName,
  ].join(".");

  const incomingReferrer = useMemo(
    () => referrerQueryParam,
    [referrerQueryParam],
  );
  const incomingEntry = useMemo(
    () => entryQueryParam ?? incomingReferrer,
    [entryQueryParam, incomingReferrer],
  );
  const incomingInitialEntry = useMemo(() => {
    if (!_incomingInitialEntry && isRoot && isTop) {
      _incomingInitialEntry = incomingEntry ?? incomingReferrer;
    }
    return _incomingInitialEntry;
  }, [incomingEntry, incomingReferrer, isRoot, isTop]);

  return useMemo(
    () => ({
      currentScreenName,
      incomingReferrer,
      incomingEntry,
      incomingInitialEntry,
      outgoingReferrer: incomingReferrer ?? currentScreenName,
      outgoingEntry: currentScreenName,
    }),
    [currentScreenName, incomingEntry, incomingInitialEntry, incomingReferrer],
  );
};
