import type { StackflowReactPlugin } from "@stackflow/react";

import { MaintenanceModeSocialCapitalActivity } from "../../widgets/social-capital/MaintenanceModeSocialCapitalActivity";

const MAINTENANCE_MODE_SOCIAL_CAPITAL_ACTIVITIES = [
  "social_capital.redirect.dashboard",
  "social_capital.onboarding",
  "social_capital.onboarding.region_selection_bottom_sheet",
  "social_capital.dashboard",
  "social_capital.ranking",
  "social_capital.point_system",
];

function isMaintenanceModeSocialCapitalActivity(activityName: string) {
  return MAINTENANCE_MODE_SOCIAL_CAPITAL_ACTIVITIES.includes(activityName);
}

export const maintenanceModeSocialCapitalPlugin = (): StackflowReactPlugin => {
  return () => ({
    key: "maintenance-mode-social-capital-plugin",
    wrapActivity({ activity }) {
      if (isMaintenanceModeSocialCapitalActivity(activity.name)) {
        return (
          <MaintenanceModeSocialCapitalActivity>
            {activity.render()}
          </MaintenanceModeSocialCapitalActivity>
        );
      }

      return <>{activity.render()}</>;
    },
  });
};
