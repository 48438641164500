import { useMemo } from "react";

import { useUserInfoStore } from "../../../entities/user/model/userInfoStore";

/**
 * 나의 당근에서 /profiles/me 로 접근하는 케이스를 대응하기 위한 컴포넌트
 */
export const useUserProfileInfoByUserId = (props: {
  profileUserId: string;
}) => {
  const user = useUserInfoStore.use.user();
  // 현재 디바이스의 유저 ID
  const currentUserId = user?.id!;
  // 프로필 유저 ID
  const profileUserId =
    props.profileUserId === "me" ? currentUserId : +props.profileUserId;

  return useMemo(
    () => ({
      profileUserId,
      isMyProfile: currentUserId === profileUserId,
    }),
    [currentUserId, profileUserId],
  );
};
