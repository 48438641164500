/**
 *
 * @param url The URL to add query parameters to. If the string "current" is provided, the current URL will be used.
 * @param params The query parameters to add to the URL.
 * @param options Options for the function. Currently only supports the encode option, which defaults to false.
 * @returns The URL with the added query parameters.
 */
export function addQueryParams(
  url: string | "current",
  params: Record<string, string | number>,
  options?: {
    encode?: boolean;
  },
): string {
  const { encode = false } = options || {};

  // Use the provided URL or the current URL based on the 'current' string
  const baseUrl = url === "current" ? window.location.href : url;

  // Check if the URL already has a query string
  const hasQuery = baseUrl.includes("?");

  // Use the appropriate separator based on whether the URL already has a query string
  const separator = hasQuery ? "&" : "?";

  // Use an array to efficiently concatenate strings
  const encodedParams: string[] = [];

  // Encode each key and value if the encode parameter is true
  for (const [key, value] of Object.entries(params)) {
    const encodedKey = encode ? encodeURIComponent(key) : key;
    const encodedValue = encode ? encodeURIComponent(value) : value;
    encodedParams.push(`${encodedKey}=${encodedValue}`);
  }

  // Construct the new URL with the added query parameters
  const newUrl = `${baseUrl}${separator}${encodedParams.join("&")}`;

  return newUrl;
}
